import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import profileImg from '../assets/img/laptop_wave.png';
import htmlIcon from '../assets/img/icons/html.png';
import cssIcon from '../assets/img/icons/css.png';
import reactIcon from '../assets/img/icons/react.png';
import pythonIcon from '../assets/img/icons/python.png';
import linuxIcon from '../assets/img/icons/linux.png';
import sqlIcon from '../assets/img/icons/sql.png';
import csharpIcon from '../assets/img/icons/c-sharp.png';
import uidesignIcon from '../assets/img/icons/ui-design.png';
import phpIcon from '../assets/img/icons/php-code.png';
import bootstrapIcon from '../assets/img/icons/bootstrap.png';
import tsIcon from '../assets/img/icons/typescript.png';
import jsIcon from '../assets/img/icons/js.png';
import nodejsIcon from '../assets/img/icons/nodejs.png';
import netIcon from '../assets/img/icons/net-framework.png';
import figmaIcon from '../assets/img/icons/figma.png';
import djangoIcon from '../assets/img/icons/django.png';



export const About = () => {
  // const responsive = {};


  return(
    <section className='AboutMe' id="aboutme">
  <Container fluid>
    <Row className="lg About">
      <Col className="box">
       
      <h2>About Me!</h2><br/>
      <p sm md>
      Hello, My name is Aman Shah.<br/>
      I am a BCS Undergraduate at  Unitec, Auckland NZ.<br/>
      I'm a Web Developer with 3+ years of experience. Currently working on App development for IOS and Android. <br/>
      <span className='social-media'>@SoftDev20</span>

      </p>
      </Col>
      <Col sm className='profile '>
        <img src={profileImg} alt='profile' className='profile-img' height={"300px"} />
      </Col>
      <Col xs={2} className='box3'></Col>
    </Row>
    <Row className='skills-row'>
    <Col xs={2} md={8}className='box3'></Col>
      <Col sm className='table-responsive-md'>
        <h2 className='skills'>Skills</h2>

      <Table  responsive="md" className="table table-borderless">
         
       <tbody>
            <tr>
              <td id="skills"><a><img height='50px' width='50px' src={htmlIcon}/></a></td>
              <td id="skills"><a><img height='50px' width='50px' src={reactIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={pythonIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={linuxIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={sqlIcon}/></a></td>

            </tr>
            <tr>
              <td id="skills"><a><img height='50px' width='50px' src={cssIcon}/></a></td>
              <td id="skills"><a><img height='50px' width='50px' src={bootstrapIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={tsIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={netIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={uidesignIcon}/></a></td>
            </tr>
            <tr>
              <td id="skills"><a><img height='50px' width='50px' src={phpIcon}/></a></td>
              <td id="skills"><a><img height='50px' width='50px' src={jsIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={nodejsIcon}/></a></td>
              <td><a><img height='50px' width='50px' src={djangoIcon}/></a></td>
              <td id="skills"><a><img height='50px' width='50px' src={figmaIcon}/></a></td>
            </tr>
          
          </tbody>
        </Table> 

      </Col>
      
     
    </Row>
  </Container>
  </section>
  )
}