import { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerIMG from "../assets/img/headerIMG.png";
import locationImg from "../assets/img/icons/pin.png";

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    const toRotate = [ "Web Developer" , "Web Designer", "UI/UX Designer" ];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)
        return () => {clearInterval(ticker)};
    }, [text]);

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
        setText(updatedText);

        if (isDeleting){
            setDelta(prevDelta => prevDelta / 2);
        }
        if (!isDeleting && updatedText === fullText){
            setIsDeleting(true);
            setDelta(period);
        }
        else if (isDeleting && updatedText === ""){
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }

    return(
        <section className="banner" id="home"> 
            <Container  fluid>
                <Row className="banner-main align-items-center">
                    <Col xs={12} md={8}>
                        <h1>{'Hello, This is Aman Shah. '}<br/><span className="wrap">{text}</span></h1>
                        <p>I am a Web Developer based in Auckland, New Zealand<img className="shadow-none" src={locationImg} alt="location" /></p>
                        <button id="button1" className="btn btn-light btn-sm shadow-sm p-3 mb-5 bg-white rounded" onClick={() => console.log('connect')}>Projects</button>
                        <button id="button2" className="btn btn-primary btn-sm shadow-sm p-3 mb-5 rounded" onClick={() => console.log('connect')}>Let's Connect<ArrowRightCircle size={25}/></button>

                    </Col>
                    
                    <Col className="headerImg" xs={6} sm={4} md> {/*-- xs={12} md={6} xl={4}*/}
                        <img className="greetingImg" src={headerIMG} height="350px" alt="headerImg"></img>
                    </Col>
                </Row>
            </Container>
        </section>
    )

}
