import { useState, useEffect } from 'react';
import {Navbar, Container, Nav} from 'react-bootstrap';
// import navIcons1 from '../assets/img/instagram.png';
// import navIcons2 from '../assets/img/github.png';
// import navIcons3 from '../assets/img/twitter.png';



export const NavBar = () => {
    const   [activeLink, setActiveLink] = useState('home');
    const   [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            }else{
                setScrolled(false);
            }
        }
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const onUpdateActiveLink =(value) => {
        setActiveLink(value);
    }

    
    return (
        <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
        <Navbar.Brand className='Brand' href="#home">&lt;Aman Shah/&gt;</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>

        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
    
          <Nav className='ms-auto'>
          <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#aboutme" className={activeLink === 'aboutme' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('aboutme')}>About</Nav.Link>
            <Nav.Link href="#project" className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('project')}>Projects</Nav.Link>
            <Nav.Link href="#contact" className={activeLink === 'connect' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>Contact</Nav.Link>

          </Nav>
         

        </Navbar.Collapse>
      </Container>
      </Navbar>
      
    )

}

