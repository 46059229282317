
import './App.css';
import {NavBar} from './components/NavBar';
import {Banner} from './components/Banner';
import {About} from './components/About';
import {Projects} from './components/Projects';
import {Contact} from './components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets/font/Poppins-Regular.ttf";


function App() {
  return (
    <div className="App">
      <NavBar/>
      <Banner/>
      <About/>
      <Projects/>
      <Contact/>
    </div>
  );
}

export default App;
